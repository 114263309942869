<template>
  <div
    class="tw-w-full lg:tw-max-w-xs tw-overflow-y-scroll tw-overflow-x-hidden tw-bg-white tw-z-30 tw-hidden lg:tw-block"
    :class="{ 'tw-hidden': closeMenu }" id="sidebar"
  >
    <a href="#" @click="closeMenu = !closeMenu" class="mt-5 ml-5 tw-block lg:tw-hidden">
      <img src="@/assets/images/icons/close.svg" alt="menu" width="36px" />
    </a>

    <div class="tw-flex tw-items-center tw-flex-col tw-h-full tw-border-r tw-border-sidebar-border">
      <router-link 
        v-on:click.native="closeMenu = !closeMenu" class="logo" 
        :to="{ name: dashboardName }"
      >
        <img 
          class="tw-mx-auto tw-my-10" 
          style="width: 70%;" 
          :src="referer.logo" 
          alt="logo" 
          v-if="!companyLogo && referer.title === 'Viqtor'" 
        />
        <img 
          class="tw-mx-auto tw-my-10" 
          style="height: 120px;" 
          :src="referer.logo" 
          alt="logo" 
          v-if="!companyLogo && referer.title !== 'Viqtor'"
        />
        <img 
          class="tw-mx-auto tw-my-10" 
          style="width: 60%;" 
          :src="companyLogo" 
          alt="company logo" 
          v-if="companyLogo" 
        />
      </router-link>

      <ul 
        id="NormalViqtorMenu"
        v-if="canShowNormalViqtorMenu"
        class="mt-5 ul-not-disabled tw-flex tw-flex-col tw-text-center tw-flex-grow"
      >
        <li :class="{ 'active-menu': subIsActive('dashboard') }">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.DashboardPage.Index' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/dashboard.svg" alt="logo" />
            Mon Dashboard
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('mon-entreprise') }">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a"
            :to="{ name: 'Company.MonEntreprisePage.Index' }" class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/entreprise.svg" alt="logo" />
            Mon Entreprise
          </router-link>
          <ul>
            <li class="" v-for="route in entrepriseMenu" :key="route.$key">
              <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: route.path }"
                class="tw-text-px-14">
                {{ route.name }}
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active-menu': subIsActive('collaborators/list') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Collaborateurs' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/collaborateurs.svg" alt="logo" />
            Collaborateurs
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('governance') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a"
          :to="{ name: 'Gouvernance.List.Page' }" class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/gouvernance.svg" alt="logo" />
            Ma gouvernance
          </router-link>
          <ul>
            <li class="" v-for="route in gouvernanceMenu" :key="route.$key">
              <router-link 
                v-on:click.native="closeMenu = !closeMenu"
                tag="a" :to="{ name: route.path }"
                class="tw-text-px-14"
                :class="{'router-link-active':route.path == $route.name}"
              >{{ route.name }}</router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active-menu': subIsActive('register') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Register.PreRegister.Page' }"
            class="sidebar-list-li-a register">
            <img class="sidebar-list-img" src="@/assets/images/icons/register.svg" alt="logo" />
            Registre de Traitement
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('ecosystem') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'EcoSystem.List' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/ecosysteme.svg" alt="logo" />
            Ecosystème
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('elearning') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Elearning' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/elearning.svg" alt="logo" />
            E-learning
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('sous-traitant') }">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'SubContractor.List' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/subcontractors.svg" alt="logo" />
            Sous-traitants
          </router-link>
          <ul>
            <li class="" v-for="route in sousTraitantsMenu" :key="route.$key">
              <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: route.path }"
                class="tw-text-px-14">
                {{ route.name }}
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active-menu': subIsActive('rgpd-processes') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.RgpdProcesses.List' }"
            class="sidebar-list-li-a" style="white-space: nowrap">
            <img class="sidebar-list-img" src="@/assets/images/icons/databreach.svg" alt="logo" />
            Mes processus RGPD
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('signature') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.Signature.DocumentsList' }"
            class="sidebar-list-li-a" style="white-space: nowrap">
            <img class="sidebar-list-img" src="@/assets/images/icons/signature.svg" alt="logo" />
            Signature
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('parametres') }">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.Parametres' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/setting.svg" alt="logo" />
            Paramètres
          </router-link>
          <ul>
            <li class="" v-for="route in parametresMenu" :key="route.$key">
              <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: route.path }"
                class="tw-text-px-14">
                {{ route.name }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>

      <ul
        id="ViqtorExpertMenu"
        v-if="canShowViqtorExpertMenu"
        class="mt-5 ul-not-disabled tw-flex tw-flex-col tw-text-center tw-flex-grow"
      >
        <li :class="{ 'active-menu': subIsActive('dashboard') }">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.Expert.DashboardPage' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/dashboard.svg" alt="logo" />
            Mon Dashboard
          </router-link>
        </li>

        <li v-if="!user.isCollaborator" :class="{ 'active-menu': subIsActive('mon-entreprise') }">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a"
            :to="{ name: 'Company.MonEntreprisePage.Index' }" class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/entreprise.svg" alt="logo" />
            Mon Entreprise
          </router-link>
          <ul>
            <li class="" v-for="route in entrepriseMenu" :key="route.$key">
              <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: route.path }"
                class="tw-text-px-14">
                {{ route.name }}
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active-menu': subIsActive('/expert-client-team') }" v-if="subscribed && !user.isCollaborator">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.ExpertClientTeam' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/collaborateurs.svg" alt="logo" />
            Mon équipe
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('/expert-client-manage-team') }" v-if="subscribed && !user.isCollaborator">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'company.expert.manage.team' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/manage_team_icon.svg" alt="logo" />
            Gérer les équipes
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('expert-client-database/list') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.ExpertClientDataBase' }"
            class="sidebar-list-li-a tw-text-left">
            <img class="sidebar-list-img" src="@/assets/images/icons/ecosysteme.svg" alt="logo" />
            Ma base de données<br />entités/clients
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('/expert-client-database/add') }" v-if="subscribed">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a"
            :to="{ name: 'Company.ExpertClientAddNew' }" class="sidebar-list-li-a register tw-text-left">
            <img class="sidebar-list-img" src="@/assets/images/icons/databreach.svg" alt="logo" />
            Créer un compte<br />entités/clients
          </router-link>
        </li>

        <li :class="{ 'active-menu': subIsActive('parametres') }">
          <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: 'Company.Parametres' }"
            class="sidebar-list-li-a">
            <img class="sidebar-list-img" src="@/assets/images/icons/setting.svg" alt="logo" />
            Paramètres
          </router-link>
          <ul>
            <li class="" v-for="route in parametresMenu" :key="route.$key">
              <router-link v-on:click.native="closeMenu = !closeMenu" tag="a" :to="{ name: route.path }"
                class="tw-text-px-14">
                {{ route.name }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>

      <div class="mt-8 sidebar-footer">© {{ year }}, viqtor.</div>
    </div>
  </div>
</template>

<script>
import { serverConfig } from "@/config/serverConfig";

export default {
  name: 'Sidebar',
  data() {
    return {
      closeMenu: false,
      gouvernanceMenu: [
        { name: "Liste de diffusion", path: "Gouvernance.List.Page" },
        { name: "Référentiels légaux", path: "Gouvernance.References.page" },
        { name: "Docuthèque", path: "Gouvernance.Docutheque.List" },
      ],
      sousTraitantsMenu: [
        { name: "Évalués", path: "SubContractor.EvaluatedSubContractors" },
        { name: "Non-évalués", path: "SubContractor.NonEvaluatedSubContractors" },
        { name: "En attente", path: "SubContractor.PendingSubContractors" },
      ],
      parametresMenu: [
        { name: "Notifications", path: "Company.Parametres.NotificationPage" },
      ],
      entrepriseMenu: [
        { name: "Questionnaire", path: "Company.MonEntreprisePage.Questionnaire" },
        { name: "Partage", path: "Company.MonEntreprisePage.Partage" },
      ],
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    user() {
      if (this.$store.getters.getAuthUser) {
        return this.$store.getters.getAuthUser;
      }
      else {
        return false;
      }
    },
    referer() {
      return this.$store.state.referrer;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.user.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    },
    companyLogo() {
      return !!this.user.workInCompany && this.user.workInCompany.logo 
        ? `${ serverConfig.appUrl }${this.user.workInCompany.logo}` 
        : null;
    },
    canShowNormalViqtorMenu() {
      return (
        (['Viqtor Expert'].includes(this.referer.title) && this.user.expertType === 'company') ||
        ['Viqtor', 'Iron Mountain', 'GDPR assist'].includes(this.referer.title)
      )
    },
    canShowViqtorExpertMenu() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.user.expertType !== "company"
      )
    },
    subscribed() {
      if (!this.user) return false;

      if (this.referer.slug == "gpmse" && !!this.user.workInCompany.planPrice) 
        return true;

      if (
        this.referer.title === 'Viqtor Expert' &&
        this.user.expertType === 'client' &&
        this.user.workInCompany.isExpertAccountActive
      ) return true;

      if (
        (this.referer.title === 'Viqtor Expert' && this.user.expertType === 'client' && this.user.isCollaborator) ||
        (this.referer.title === 'Viqtor Expert' && this.user.expertType === 'company')
      ) return true;

      if (this.user.workInCompany.planPrice && this.user.workInCompany.planPrice.price !== 0)
        return true;

      return false;
    },
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) > 0;
      });
    },
  },
}
</script>