<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-24 tw-h-full tw-flex tw-flex-col tw-w-full tw-mx-auto" style="max-width: 1536px;">
      <img :src="logo" style="width: 170px;" alt="LOGO" />
      
      <div class="tw-flex tw-items-center tw-justify-between tw-my-auto">
        <div>
          <p style="line-height: 40px;">
            <span class="tw-font-semibold tw-text-4xl">Bienvenue sur Viqtor®,</span> <br />
            <span class="tw-font-semibold tw-text-2xl">la plateforme souveraine de la conformité RGPD.</span>
          </p>
          <p style="line-height: 36px;" class="tw-mt-6">
            <span class="tw-font-medium tw-text-xl">À l’initiative du GPMSE, vous êtes entré dans un processus de labellisation.</span><br />
            <span class="tw-font-medium tw-text-xl">Les dispositions que vous avez mises en place pour la protection des données personnelles <br /> y jouent un rôle clé.</span><br />
            <span class="tw-font-medium tw-text-xl" :style="{ color: theme.ctaBgColor }">Vous allez pouvoir évaluer la conformité de votre entreprise en accédant au questionnaire d'évaluation.</span>
          </p>
  
          <p class="tw-mt-8" style="line-height: 36px;">
            <span class="tw-font-light tw-text-xl">Une fois que vous aurez valide votre questionnaire, votre score s'affichera automatiquement.</span> <br />
            <span class="tw-font-light tw-text-xl">Les points de vigilance ou les "trous dans la raquette" vous seront signales.</span>
          </p>
  
          <p class="tw-mt-8">
            <span class="tw-font-light tw-text-xl" :style="{ color: theme.ctaBgColor }">Nous vous souhaitons une bonne évaluation !</span>
          </p>
  
          <PrimaryButtonComponent
            @click="$router.push({ name: 'GdprQuizGpmse.register' })"
            class="tw-mt-8"
            elevation="0"
            :style="{
              backgroundColor: `${theme.ctaBgColor} !important`,
              color: `${theme.ctaTextColor} !important`,
            }"
          ><span style="font-size: 16px; font-weight: 500;">Poursuivre ></span></PrimaryButtonComponent>
        </div>
        <img src="/images/gpmse_landing.png" style="width: 321px;" alt="landing" />
      </div>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import Footer from "@/components/layout/Footer";

export default {
  name: "GdprQuizGpmseLanding",
  components: {
    PrimaryButtonComponent,
    Footer,
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
};
</script>