<template>
  <div>
    <h2>Mon score en détails</h2>
    <v-simple-table id="contact-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Chapitre</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in table_data" :key="item.id">
            <td>{{ key + 1 }}. {{ item.chapter.label }}</td>
            <td>
              <span :class="getBadge(item.status)" v-if="key !== 9">
                {{ item.scorePercent !== null ? item.scorePercent.toFixed() : 0 }} / 100
              </span>
              <span v-if="key === 9" @click="showDetailsDialog = true"
                class="tw-ml-2 tw-text-xs tw-px-3 tw-py-1 tw-rounded-full tw-font-normal tw-cursor-pointer"
                style="border: 1px solid #716861; color: #716861;">Détail</span>
            </td>
          </tr>
          <tr>
            <td>Total:</td>
            <td>
              <span class="tw-whitespace-nowrap" :class="getLgBadge()">{{ total !== null ? total.toFixed() : 0 }} /
                100</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
      <v-btn elevation="1" text rounded large @click="$emit('voirDetail')" class="tw-text-white victor-submit-button-detail">
        Voir le détail
      </v-btn>
    </div>

    <v-dialog
      v-if="showDetailsDialog"
      v-model="showDetailsDialog"
      max-width="800px"
    >
      <v-card flat>
        <v-card-title class="tw-text-lg tw-font-bold">
          10. {{ table_data[9].chapter.label }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="showDetailsDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="tw-space-y-6">
            <div 
              v-for="(question, questionIndex) in chapter10" :key="question.id" 
              class="tw-pb-4"
              :class="{ 'tw-border-b tw-border-gray-200': questionIndex !== chapter10.length - 1 }"
            >
              <h3 class="tw-font-medium tw-mb-3">
                10.{{ questionIndex + 1 }}. {{ question.label }}
              </h3>
              <div class="tw-pl-4">
                <div 
                  v-for="(response, responseIndex) in question.responses" 
                  :key="responseIndex"
                  class="tw-mb-2"
                >
                  <span class="tw-text-gray-700">
                    {{ question.responses.length > 1 ? "• " : "" }}{{ response }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ChaptersScoresList',
  props: {
    table_data: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    chapter10: {
      type: Array,
      required: true,
    },
    scoreTotal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDetailsDialog: false,
    };
  },
  methods: {
    getBadge(score) {
      if (score =="A") return "victor-badge-green";
      else if (score =="B") return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getLgBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-lg-badge-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-lg-badge-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
  }
}
</script>