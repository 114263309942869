<template>
  <div id="Questionnaire-container"  class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <div class="tw-flex tw-items-center tw-justify-between">
        <img :src="logo" style="width: 170px;" alt="LOGO" />
        <span @click="logout" class="tw-cursor-pointer" :style="{ color: theme.ctaBgColor }">Se déconnecter</span>
      </div>

      <EntrepriseHeaderBlockTextComponent class="tw-mt-10" title="Back-office"
        :description="socialReason ? `Détail du questionnaire de ${socialReason}.` : ''" />

      <div class="tw-items-center tw-flex tw-flex-col">
        <template v-if="loading">
          <content-placeholders class="marginTop">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </template>
        <template v-else>
          <div class="tw-grid tw-grid-cols-5 tw-gap-8 tw-justify-items-center">
            <div class="tw-col-span-5 lg:tw-col-span-2 box-questionnaire">
              <span class="score" style="margin-top: 0;" :class="getColorBadge()">
                {{ total !== null ? total.toFixed() : 0 }} / 100
              </span>
              
              <div class="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-mx-auto tw-w-full">
                <div class="tw-w-full">
                  <div 
                    class="tw-flex tw-items-center tw-justify-around tw-font-semibold tw-text-white tw-text-sm tw-rounded-lg"
                    :style="{ backgroundColor: `${theme.ctaBgColor} !important` }"
                    style="padding: 2px 4px;"
                  >
                    <span>0</span>
                    <span>20</span>
                    <span>40</span>
                    <span>60</span>
                    <span>80</span>
                    <span>100</span>
                  </div>
                  <div style="width: 82%; margin-left: 10px;">
                    <svg class="tw-mt-1" :class="getColorBadge()" :style="{ marginLeft: `${total}%` }" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19" /></svg>
                  </div>
                </div>

                <span class="tw-text-sm tw-font-bold tw-mt-2" :class="getColorBadge()">{{ scoreMessage.message }}</span>

                <p class="tw-text-sm tw-text-center" style="margin-top: .25rem; line-height: 1rem;">
                  {{ scoreMessage.description }}
                </p>
              </div>
              
              <hr />
              <p style="margin-top: .5rem;">
                Du changement concernant votre conformité ?
                Une fois par an, vous avez la possibilité de modifier vos réponses
                au questionnaire et faire évoluer votre score.
              </p>
            </div>
            <ChaptersScoresList 
              class="tw-col-span-5 lg:tw-col-span-3 box-score"
              :table_data="table_data"
              :total="total"
              :chapter10="chapter10"
              :score-total="scoreTotal"
              @voirDetail="$router.push({ name: 'GdprQuizGpmse.quizFollowup.Score.Detail', params: { userId: $route.params.userId }})" 
            />
          </div>
        </template>

        <v-btn
          v-if="!loading"
          elevation="1" text rounded large
          :style="{
            backgroundColor: `${theme.ctaBgColor} !important`,
            color: `${theme.ctaTextColor} !important`,
            borderColor: `${theme.ctaBgColor} !important`,
          }"
          style="width: 120px;"
          class="tw-mt-4"
          @click="$router.back()"
        >Retour</v-btn>
      </div>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import ChaptersScoresList from "@/features/company/components/Questionnaire/ChaptersScoresList";
import Footer from "@/components/layout/Footer";
import { getQuiz } from "@/features/gpmse/services/api";

export default {
  name: "GdprQuizGpmseFollowupScore",
  components: {
    EntrepriseHeaderBlockTextComponent,
    ChaptersScoresList,
    Footer,
  },
  data() {
    return {
      socialReason: null,
      loading: false,
      scoreTotal: null,
      total: null,
      table_data: [],
      scoreMessages: [
        {
          message: "C’est un excellent score !",
          description: `
            Vous êtes dans une excellente dynamique de conformité,
            continuez à maintenir ces bonnes pratiques et
            renouvelez l’exercice régulièrement pour rester en tête.
          `,
          condition: (score) => score >= 85,
        },
        {
          message: "C’est un bon score !",
          description: `
            Quelques améliorations mineures, et davantage de bonnes pratiques,
            vous permettraient d’atteindre une conformité encore plus forte.
            Continuez sur cette voie pour progresser !
          `,
          condition: (score) => score >= 70 && score < 85
        },
        {
          message: "C’est un score encourageant !",
          description: `
            Des améliorations sont nécessaires pour garantir
            une meilleure conformité. En travaillant sur
            quelques points spécifiques, vous pourrez rapidement progresser.
          `,
          condition: (score) => score >= 55 && score < 70
        },
        {
          message: "C’est un score honorable !",
          description: `
            Ce résultat montre que des efforts doivent être faits pour assurer
            une conformité adéquate. Il est essentiel de revoir certaines pratiques
            et de mettre en place des mesures correctives.
          `,
          condition: (score) => score >= 40 && score < 55
        },
        {
          message: "C’est un score frustrant !",
          description: `
            Il est temps d’entreprendre des actions pour rectifier le tir.
            Ce score indique que des failles subsistent en termes de conformité.
            Un plan d’action urgent est nécessaire pour améliorer cette situation.
          `,
          condition: (score) => score < 40
        }
      ],
      chapter10: []
    };
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    scoreMessage() {
      return this.scoreMessages.find((message) => message.condition(this.total));
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      getQuiz(this.$route.params.userId)
        .then(({ data }) => {
          this.socialReason = data.data.socialReason;
          this.table_data   = data.data.quiz.quizChapters.sort((a, b) => a.chapterId - b.chapterId);
          this.scoreTotal   = data.data.quiz.status;
          this.showQuestion = !data.data.quiz.isDraft;
          this.total = data.data.quiz.scorePercent;
          this.loading = false;

          this.chapter10 = this.table_data.find(chapter => chapter.chapterId === 10).quizQuestions.map((q) => ({
            label: q.question.label,
            responses: q.responses.map(r => r.label)
          }));
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message 
              || 'Une erreur est survenue lors de la récupération des données',
            type: "error",
          });
          this.loading = false;
        })
    },
    getBadge(score) {
      if (score =="A") return "victor-badge-green";
      else if (score =="B") return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    getLgBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-lg-badge-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-lg-badge-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },
  }
}
</script>