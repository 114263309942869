<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div id="collaborateurs-add" style="padding-bottom: 2rem;">
      <div
        class="tw-flex tw-align-start tw-justify-between"
        id="mes-partages-header"
      >
        <EntrepriseHeaderBlockTextComponent
          title="Ajouter un ou plusieurs sous-traitants"
          description="Pour ajouter manuellement un sous-traitant, veuillez remplir les champs ci-dessous :"
        />
      </div>
      
      <SoustraitantFormAddComponent class="tw-mt-8" />
      
      <img src="@/assets/images/icons/hr.svg" class="hr_img" />
      
      <div class="collaborators-row tw-mt-8">
        <div class="collaborators-col-8">
          <CollaborateurBlockTextComponent
            title="1. Téléchargez d’abord le modèle"
            description="Pour vous permettre d’importer avec plus de facilité la liste de vos sous-traitants, téléchargez ce modèle."
          />
          
          <v-btn
            elevation="1"
            text
            rounded
            large
            @click="downloadCSVData"
            download
            class="tw-cursor-pointer tw-mt-4"
            outlined
            :style="{
              backgroundColor: `white !important`,
              color: `${theme.ctaBgColor} !important`,
            }"
          >
            Télécharger le modèle 
            <img src="@/assets/images/icons/download.svg" style="margin-left: 1rem;" />
          </v-btn>
          
          <p class="tw-mt-4" style="line-height: 2;">
            Ce fichier est un Excel .csv comprenant 5 colonnes, dans l’ordre : le numéro de SIREN, la civilité, le prénom et le nom du contact ainsi que son adresse email.<br>
            Ne modifiez pas l’ordre des colonnes et renseignez autant de sous-traitants que souhaité.<br>
            <b>Vous pouvez importer jusqu’à 50 sous-traitants par fichier.</b>
          </p>
        </div>

        <div class="collaborators-col-4 tw-mt-4">
          <CollaborateurBlockTextComponent
            title="2. Importez votre liste de sous-traitants"
            description="Pour vous permettre de d’importer avec plus de faciliter la liste de vos sous-traitants, téléchargez ce modèle."
          />

          <vue-dropzone 
								ref="myVueDropzone" 
								id="dropzone" 
								:useCustomSlot="true" 
								:options="dropzoneOptions" 
								class="upload-file tw-mt-8" 
								:includeStyling="false"
								@vdropzone-removed-file="fileRemoved" 
                @vdropzone-file-added="fileAdded"
								>
								<div class="dropzone-custom-content tw-flex tw-items-center tw-flex-col">
									<img src="@/assets/images/icons/upload.svg" />
									Glissez et déposez ici votre liste de sous-traitants 
									<v-btn
									elevation="1"
									text
									rounded
									large
									:loading="isUploadModel"
									class="tw-text-white tw-cursor-pointer"
									>
									Parcourir
									</v-btn>
								</div>
							</vue-dropzone>
          
          <div class="tw-w-full tw-flex">
            <v-btn
              elevation="1"
              text
              rounded
              large
              :style="{
                backgroundColor: `${theme.ctaBgColor} !important`,
                color: `${theme.ctaTextColor} !important`,
              }"
              :loading="isImportFile"
              @click="importFile"
              class="tw-text-white tw-cursor-pointer tw-mx-auto"
            >
              Continuer
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import CollaborateurBlockTextComponent from "@/components/common/CollaborateurBlockTextComponent";
import SoustraitantFormAddComponent from "@/features/auth/components/Collaborateurs/SoustraitantFormAddComponent";
import { importCollaborators } from "../../services/appApi";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { serverConfig } from "@/config/serverConfig";

export default {
  name: "AddCollaborateursPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SoustraitantFormAddComponent,
    CollaborateurBlockTextComponent,
    vueDropzone: vue2Dropzone,
  },
  computed: {
    fileInputText() {
      return this.file ? "" : "AUCUN FICHIER CHOISI";
    },
    fileLInk() {
      return require("@/assets/csv/collaborators.csv");
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      isUploadModel: false,
      isImportFile: false,
      file: null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 50,
        maxFilesize: 0.5,
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFile:1,
        dictRemoveFile: `<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
        uploadMultiple:1,
      },
      text:
        '<table\r\n id="email" role="presentation"\r\n border="0"\r\n cellpadding="0"\r\n cellspacing="0"\r\n class="body"\r\n>\r\n <tr>\r\n <td>&nbsp;</td>\r\n <td class="container">\r\n <div class="content">\r\n <!-- START CENTERED WHITE CONTAINER -->\r\n <table role="presentation" class="main">\r\n <!-- START MAIN CONTENT AREA -->\r\n <tr>\r\n<td class="wrapper"><table\r\n role="presentation"\r\n border="0"\r\n cellpadding="0"\r\n cellspacing="0"\r\n >\r\n <tr>\r\n <td>\r\n <div\r\n style="\r\n display: flex;flex-direction:column;align-items:center;gap:40px;\r\n justify-content: center;\r\n align-items: center;\r\n margin: 2rem 0;\r\n "\r\n >\r\n<img\r\nsrc="https://i.ibb.co/7ynkVts/viqtor-noir2.png"\r\nwidth="200"\r\n/>\r\n<img\r\nsrc="https://i.ibb.co/7ynkVts/viqtor-noir.png"\r\nalt="logo"\r\nwidth="120"\r\n/>\r\n </div>\r\n <h1 class="purpleheader">\r\n ' +
        this.$store.state.user.workInCompany.socialReason +
        "\r\n <br />\r\n \u00C9metteur : " +
        this.$store.state.user.firstName +
        " " +
        this.$store.state.user.lastName +
        "/ " +
        this.$store.state.user.role +
        '\r\n </h1>\r\n</td>\r\n </tr>\r\n<tr>\r\n<td class="purplefooter"><div>INFORMATION \u00C0 NOS FOURNISSEURS ET SOUS-TRAITANTS</div></td>\r\n</tr>\r\n<tr>\r\n<td>\r\n <div class="br">\r\n <br />\r\n <br />\r\n Bonjour,\r\n <br />\r\n <br />\r\n Dans le cadre de notre  mise en conformit\u00E9 au RGPD [R\u00E8glement\r\n G\u00E9n\u00E9ral sur la Protection des donn\u00E9es], nous avons\r\n l\u2019obligation d\u2019\u00E9valuer la conformit\u00E9 de nos\r\n partenaires, fournisseurs et sous-traitants, dont vous\r\n faites partie.\r\n <br />\r\n <br />\r\n C\u2019est important, car votre conformit\u00E9 au RGPD est\r\n d\u00E9terminante pour notre conformit\u00E9 et ses risques\r\n associ\u00E9s, business comme financiers.\r\n <br />\r\n <br />\r\n Sans cette \u00E9valuation il deviendra compliqu\u00E9 de\r\n poursuivre des relations commerciales avec votre\r\n entreprise.\r\n <br />\r\n <br />\r\n Nous avons choisi de vous \u00E9valuer avec Viqtor\u00AE, la\r\n seule plateforme qui propose un module d\u00E9di\u00E9, tr\u00E8s\r\n simple et surtout rapide.\r\n <br />\r\n <br />\r\n En cliquant sur le lien unique et s\u00E9curis\u00E9 ci-dessous,\r\n laissez-vous guider, vous en aurez montre en main pour\r\n \u00E0 peines quelques minutes.\r\n </div>\r\n </td>\r\n </tr>\r\n <td class="purplefooter" style="padding: 1rem" ><div><p style="text-align: left;color:#FFFFFF">Vous avez été identifié comme l’interlocuteur de ' +
        this.$store.state.user.workInCompany.tradeName +
        ' pour répondre au questionnaire d’évaluation de votre conformité au RGPD.<br/><br/>Êtes-vous bien le bon interlocuteur pour répondre à cette évaluation qui engage votre entreprise ?</p><br /><table><tr><td><a style="color:white; border-color: white" href="{{link}}?answer=oui">Oui</a></td><td><a style="color:white; border-color: white" href="{{link2}}?answer=non">Non</a></td></tr></table></div></td></tr><tr><td><br/>\r\n <br/>\r\n <div class="br">\r\n \u00C0 l\u2019issue de votre \u00E9valuation, et ce, quel que soit\r\n le r\u00E9sultat, nous reprendrons contact avec vous.\r\n  <br/>\r\n  <br/>\r\n Cordialement, \r\n  <br/>\r\n ' +
        this.$store.state.user.firstName +
        " " +
        this.$store.state.user.lastName +
        '\r\n </div>\r\n <br />\r\n <br />\r\n</td>\r\n</tr>\r\n</table>\r\n</td>\r\n</tr>\r\n\r\n<!-- END MAIN CONTENT AREA -->\r\n                </table>\r\n                <!-- END CENTERED WHITE CONTAINER -->\r\n\r\n                <!-- START FOOTER -->\r\n                <div class="footer">\r\n<table\r\n        role="presentation"\r\n        border="0"\r\n        cellpadding="0"\r\n        cellspacing="0"\r\n>\r\n    <tr>\r\n        <td class="content-block">\r\nViqtor\u00AE est une plateforme con\u00E7ue pour piloter la conformit\u00E9\r\nde votre entreprise au RGPD.<br />\r\nCe premier module gratuit vous permet de commencer votre\r\nmise en conformit\u00E9 en \u00E9valuant<br />\r\nvos sous-traitants pour vous pr\u00E9munir des risques business\r\net financiers\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td class="mb mt">\r\n\r\n\r\n        </td>\r\n        <td></td>\r\n    </tr>\r\n    <tr>\r\n        <td class="purplefooter">\u00A9 ' +
        new Date().getFullYear() +
        ', viqtor.</td>\r\n    </tr>\r\n    <tr>\r\n        <td class="mt">\r\nConfidentialit\u00E9 I Conditions d\u2019utilisation\r\n<p class="br" style="text-align: center">\r\nViqtor \u00AE/ DBWO SAS - 70 rue de Ponthieu - 75008 Paris -\r\nFrance\r\n</p>\r\n        </td>\r\n    </tr>\r\n</table>\r\n                </div>\r\n                <!-- END FOOTER -->\r\n            </div>\r\n</td>\r\n<td>&nbsp;</td>\r\n    </tr>\r\n</table>',
    };
  },
  mounted() {
    if (this.$route.params.type === "many") {
      this.IsimportMany = true;
    }
    const referrer = this.referer.title;
    this.text = this.text.replaceAll("Viqtor", referrer);
    this.text = this.text.replaceAll("viqtor.", referrer);
    this.text = this.text.replaceAll("https://i.ibb.co/7ynkVts/viqtor-noir.png", this.referer.logoEmail);
    this.text = this.$store.getters.getAuthUser.workInCompany.logo ?
      this.text.replaceAll(`https://i.ibb.co/7ynkVts/viqtor-noir2.png`,`${serverConfig.appUrl}${this.$store.getters.getAuthUser.workInCompany.logo }`):
      this.text.replaceAll(`https://i.ibb.co/7ynkVts/viqtor-noir2.png"`,`" style="display:none;"`);
  }, 
  methods: {

    fileRemoved() {
      this.file = null;
    },
    fileAdded(file){
			if(this.file){
				this.$refs.myVueDropzone.removeFile(this.file)
			}
			this.file = file;
		},
    importFile() {
      this.isImportFile = true;
      const data = new FormData();
      data.append("file", this.file);
      data.append("emailTemplate", this.text);
      importCollaborators(this.$route.params.token, data)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.$router.push({
            name: "MsgSousTraitant",
          });
        })
        .catch(({ response }) => {
          if(response.status === 500){
            this.$snackbar.showAlertMessage({
              message: "Ficher importé",
              type: "success",
            });
            this.$router.push({
              name: "Company.MonEntreprisePage.Collaborateurs.invitation",
            });
          }else {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                text: "fichier incorrect.",
              });
            }
          })
          .finally(() => {
            this.isImportFile = false;
          });
    },
    downloadCSVData() {
			var str = "SIREN;GENRE;PRENOM;NOM DE FAMILLE;EMAIL;RAISON SOCIALE";
      var blob = new Blob([str], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "viqtor-sous-traitant-model.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
