<template>
  <v-form @submit="onSubmit" v-model="formValid" class="mt-23" style="padding-bottom: 2rem;">
    <div class="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-3">
    
      <SelectBoxComponent
        v-model="form.gender"
        :items="genderItems"
        :hide-details="!errors.gender"
        :error="!!errors.gender"
        :error-messages="errors.gender"
      />
      <TextFieldComponent
        v-model="form.firstName"
        label="Prénom"
        name="firstName"
        :hide-details="!errors.firstName"
        :error="!!errors.firstName"
        :error-messages="errors.firstName"
      />
      <TextFieldComponent
        v-model="form.lastName"
        label="Nom"
        name="lastName"
        :hide-details="!errors.lastName"
        :error="!!errors.lastName"
        :error-messages="errors.lastName"
      />
      <TextFieldComponent
        v-model="form.email"
        label="Adresse e-mail"
        :error="!!errors.email"
        :error-messages="errors.email"
      />
      <TextFieldComponent
        v-model="form.sirenOrRna"
        label="SIREN / RNA"
        containerStyle="red"
        :error="!!errors.sirenOrRna"
        :error-messages="errors.sirenOrRna"
      />
      <TextFieldComponent
        v-model="form.socialReason"
        label="Raison Sociale"
        :error="!!errors.socialReason"
        :error-messages="errors.socialReason"
      />
    </div>
    
    <SubmitButtonComponent
      class="tw-mt-4"
      :style="{
        backgroundColor: `${theme.ctaBgColor} !important`,
        color: `${theme.ctaTextColor} !important`,
      }"
      :loading="isSendingForm"
      type="submit"
      >{{ formSubmitButton }}
    </SubmitButtonComponent>
  </v-form>
</template>
<script>
import TextFieldComponent from "@/components/common/TextFieldComponent";
import SelectBoxComponent from "@/components/common/SelectBoxComponent";
import SubmitButtonComponent from "@/components/common/SubmitButtonComponent";
import { storeCollaborator } from '../../services/appApi';

export default {
  name: "SoustraitantFormAddComponent",
  computed: {
    formSubmitButton() {
      return "Continuer";
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  components: {
    TextFieldComponent,
    SubmitButtonComponent,
    SelectBoxComponent
  },
  data() {
    return {
      isSendingForm: false,
      formValid: false,
      disableInput: true,
      genderItems: [
        { label: "Madame", value: "female" },
        { label: "Monsieur", value: "male" },
      ],
      errors: {
        sirenOrRna: "",
        email: "",
        firstName: "",
        lastName: "",
        socialReason: "",
        gender: "",
      },
      form: {
        gender: "",
        email: "",
        firstName: "",
        lastName: "",
        sirenOrRna: "",
        socialReason: "",
      },
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.isSendingForm = true;
      this.errors = {
        sirenOrRna: "",
        email: "",
        firstName: "",
        lastName: "",
        socialReason: "",
        gender: "",
      };

      this.form.emailTemplate = this.$parent.text;
      storeCollaborator(this.$route.params.token,this.form).then(() => {
        this.$router.push({
          name: "MsgSousTraitant",
        });
      }).catch((error) => {
        console.log(error?.response?.data?.message);

        if (error?.response?.data?.message) {
          error.response.data.message.forEach(error => {
            this.errors[error.property] = Object.values(error.constraints)[0];
          });
        }

        this.$notify({
          group: "foo",
          type: "error",
          title: "Attention",
          text: "Erreur de validation de données",
        });      
      }).finally(()=>{
        this.isSendingForm = false;
      });
      
    },
  },
};
</script>
