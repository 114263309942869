<template>
  <div class="dynamic-text">
    <activities-list-full class="tw-my-2" :activities="oldActivities" />
    <div class="tw-space-y-2">
      <div v-for="(activity, index) in activities" :key="index" class="tw-flex tw-full">
        <div
          class=" tw-w-2/12 tw-py-2 tw-px-4 tw-rounded-xl tw-text-white tw-font-semibold tw-text-sm tw-text-center dynamic-bg"
        >
          Activité {{ lengthActivicties+(index+1) }} :
        </div>
        <input
          class="
            tw-w-4/12
            tw-ml-[-8px]
            tw-border-2
            tw-border-l-0
            tw-border-solid
            tw-text-sm
            tw-py-2
            tw-pl-4
            tw-pr-2
          "
          :style="{ borderColor: `${referer.theme.ctaBgColor} !important` }"
          type="text"
          placeholder="ex. Gestion de la paie"
          v-model="activity.name"
          @change="handleActivitiesChanged"
        />
        <input
          class=" tw-w-2/12 tw-border-2 tw-border-l-0 tw-border-solid tw-text-sm tw-py-2 tw-pl-4 tw-pr-2"
          :style="{ borderColor: `${referer.theme.ctaBgColor} !important` }"
          type="text"
          placeholder="Fonction"
          v-model="activity.function"
          @change="handleActivitiesChanged"
        />
        <!-- <input
          class=" tw-w-2/12 tw-border-2 tw-border-l-0 tw-border-solid tw-text-sm tw-py-2 tw-pl-4 tw-pr-2"
          :style="{ borderColor: `${referer.theme.ctaBgColor} !important` }"
          type="text"
          placeholder="Nom"
          v-model="activity.lastName"
          @change="handleActivitiesChanged"
        /> -->
        <input
          class=" tw-w-4/12 tw-border-2 tw-border-l-0 tw-border-solid tw-text-sm tw-py-2 tw-pl-4 tw-pr-2 tw-rounded-r-xl"
          :style="{ borderColor: `${referer.theme.ctaBgColor} !important` }"
          type="email"
          placeholder="e-mail"
          v-model="activity.email"
          @change="handleActivitiesChanged"
        />
      </div>
    </div>
    <div class="tw-mt-6 tw-mr-6 tw-flex tw-justify-end">
      <div
        class=" tw-w-max tw-p-2 tw-flex tw-items-center tw-space-x-4 tw-rounded-2xl tw-cursor-pointer tw-border-2"
        :style="{ borderColor: `${referer.theme.ctaBgColor} !important` }"
        @click="addActivitySlot"
      >
        <span class="tw-font-semibold tw-text-xs">Ajouter une activité</span>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9914 7.89314H9.10742V5.00921C9.10742 4.92573 9.03912 4.85742 8.95564 4.85742H8.04492C7.96144 4.85742 7.89314 4.92573 7.89314 5.00921V7.89314H5.00921C4.92573 7.89314 4.85742 7.96144 4.85742 8.04492V8.95564C4.85742 9.03912 4.92573 9.10742 5.00921 9.10742H7.89314V11.9914C7.89314 12.0748 7.96144 12.1431 8.04492 12.1431H8.95564C9.03912 12.1431 9.10742 12.0748 9.10742 11.9914V9.10742H11.9914C12.0748 9.10742 12.1431 9.03912 12.1431 8.95564V8.04492C12.1431 7.96144 12.0748 7.89314 11.9914 7.89314Z"
            fill="var(--cta-bg-color)"
          />
          <path
            d="M8.5 0C3.80603 0 0 3.80603 0 8.5C0 13.194 3.80603 17 8.5 17C13.194 17 17 13.194 17 8.5C17 3.80603 13.194 0 8.5 0ZM8.5 15.558C4.6029 15.558 1.44196 12.3971 1.44196 8.5C1.44196 4.6029 4.6029 1.44196 8.5 1.44196C12.3971 1.44196 15.558 4.6029 15.558 8.5C15.558 12.3971 12.3971 15.558 8.5 15.558Z"
            fill="var(--cta-bg-color)"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import ActivitiesListFull from './ActivitiesListFull';

export default {
  props: {
    oldActivities: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ActivitiesListFull,
  },
  data() {
    return {
      activities: [
        {
          name: "",
          function: "",
          // lastName: "",
          email: "",
        },
        {
          name: "",
          function: "",
          // lastName: "",
          email: "",
        },
        {
          name: "",
          function: "",
          // lastName: "",
          email: "",
        },
        {
          name: "",
          function: "",
          // lastName: "",
          email: "",
        },
        {
          name: "",
          function: "",
          // lastName: "",
          email: "",
        },
        {
          name: "",
          function: "",
          // lastName: "",
          email: "",
        },
        {
          name: "",
          function: "",
          // lastName: "",
          email: "",
        },

      ],
    };
  },
  computed:{
    lengthActivicties(){
      return this.oldActivities.length;
    },
    referer() {
      return this.$store.state.referrer;
    }
  },
  methods: {
    addActivitySlot() {
      this.activities.push({
        name: "",
        function: "",
        // lastName: "",
        email: "",
      });
    },
    handleActivitiesChanged() {
      this.$emit('handleActivitiesChanged', this.activities);
    }
  },
};
</script>
