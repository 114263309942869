export default {
    title: 'viqtor',
    receiverEmail: 'catchall-technique@veniseactivation.com',
    conatctPhone: '01 81 80 11 11',
    contactAddress: "11 rue de Ponthieu 75008<br />Saint",
    landingText: "Il faut bien que vous commenciez par démêler le fil de la conformité au RGPD. Cette obligation est une nécessité pour pérenniser l’activité de votre entreprise, tant les risques encourus sont importants. Alors allez y pas-à-pas en commençant par l’un des sujets les plus sensibles qui consiste à évaluer vos sous-traitants. Une fois inscrit, vous en aurez à peine pour 20 mn.",
    landingButton: "Inscription Gratuite",
    hasMaintenance: false,
    theme: {
        svg1Color: 'black',
        textColor3: "#FFCE73",
        ctaBgColor: "#6c5dd3",
        ctaTextColor: "#FFFFFF",
        cta2BgColor: "#FFFFFF",
        cta2TextColor: "#14477E",
        bgColor1: "#2c2560",
        bgColor2: "#6c5dd3",
        icon1Color: "#FFFFFF",
        icon1BgColor: "#6c5dd3",
        icon2Color: "#1D1D1B",
        icon2BgColor: "#FFCE73",
        icon3Color: "#FFFFFF",
        icon3BgColor: "#2C2560",
        contactIconColor1: "rgb(108, 93, 211)",
        contactIconColor2: "rgb(108, 93, 211)",
        contactIconColor3: "rgb(108, 93, 211)",
        contactBtnBgColor: "#6c5dd3",
        contactBtnTextColor: "#FFFFFF",
        contactBgColor1: "#ffffff",
        contactBgColor2: "#6c5dd3",
        contactBgColor3: "#897DDC",
        logoWidth: "150px",
        logoHeight: "auto",
        headerPadding: "25px",
        barchartItem1Color: "#6C5DD3",
        barchartItem2Color: "#A0D7E7",
        evaluatedProgressColor: "#ffa2c0",
        firstStepProgressquiz: "#B5AEE9",
        secondStepProgressquiz: "#988DE1",
        lastStepProgressquiz: "#6C5DD3",
        rgpdProcessesOne: "#C5B0FF",
        rgpdProcessesTwo: "#AD8DFE",
        rgpdProcessesThree: "#936EE0",
        rgpdProcessesFour: "#6C5DD3",
        rgpdProcessesFive: "#44B480"
    },
    text: {
        landingBannerTitle: "Faites du RGPD <br /> une opportunité <br /> pour votre entreprise.<br />",
        rgpdRuleTitle: "Mettez-vous<br />en règle avec<br />le RGPD",
        signSectionText: "Déroulez votre mise en conformité",
        signSectionButtonText: "S’inscrire",
        contactSectionText: "J'adorerais vous rencontrer pour discuter de votre entreprise,<br class=\"md:tw-hidden\" /> et les collaborations potentielles.",
        resultSectionText: "Le RGPD a vocation à protéger les données personnelles et à renforcer le contrôle des personnes sur les données qu’elles disséminent de manière plus ou moins consciente.<br /><br /> Dans ce contexte, votre entreprise , votre organisation doit revisiter le chemin de l’information dans son système de collecte et de traitement des données personnelles.<br /><br /> C’est le cœur de votre projet : comprendre quelles données personnelles sont nécessaires à la création de valeur de votre organisation, comment leur protection est assurée et comment ce souci est partagé par vos collaborateurs et partenaires.<br /><br /> Vous allez construire votre conformité, va vous l’écrire pour que vous puissiez en faire part à chacun.",
    },
    logo: "https://viqtor.digital/images/logo-2.png",
    favicon: "https://viqtor.digital/IM-FAVICON.svg",
    paymentSetup: { regulation: 'monthly', engagement: 24 },
}
