var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"tw-pt-0 tw-mt-0"},[_c('div',{staticStyle:{"padding-bottom":"2rem"},attrs:{"id":"collaborateurs-add"}},[_c('div',{staticClass:"tw-flex tw-align-start tw-justify-between",attrs:{"id":"mes-partages-header"}},[_c('EntrepriseHeaderBlockTextComponent',{attrs:{"title":"Ajouter un ou plusieurs sous-traitants","description":"Pour ajouter manuellement un sous-traitant, veuillez remplir les champs ci-dessous :"}})],1),_c('SoustraitantFormAddComponent',{staticClass:"tw-mt-8"}),_c('img',{staticClass:"hr_img",attrs:{"src":require("@/assets/images/icons/hr.svg")}}),_c('div',{staticClass:"collaborators-row tw-mt-8"},[_c('div',{staticClass:"collaborators-col-8"},[_c('CollaborateurBlockTextComponent',{attrs:{"title":"1. Téléchargez d’abord le modèle","description":"Pour vous permettre d’importer avec plus de facilité la liste de vos sous-traitants, téléchargez ce modèle."}}),_c('v-btn',{staticClass:"tw-cursor-pointer tw-mt-4",style:({
              backgroundColor: `white !important`,
              color: `${_vm.theme.ctaBgColor} !important`,
            }),attrs:{"elevation":"1","text":"","rounded":"","large":"","download":"","outlined":""},on:{"click":_vm.downloadCSVData}},[_vm._v(" Télécharger le modèle "),_c('img',{staticStyle:{"margin-left":"1rem"},attrs:{"src":require("@/assets/images/icons/download.svg")}})]),_c('p',{staticClass:"tw-mt-4",staticStyle:{"line-height":"2"}},[_vm._v(" Ce fichier est un Excel .csv comprenant 5 colonnes, dans l’ordre : le numéro de SIREN, la civilité, le prénom et le nom du contact ainsi que son adresse email."),_c('br'),_vm._v(" Ne modifiez pas l’ordre des colonnes et renseignez autant de sous-traitants que souhaité."),_c('br'),_c('b',[_vm._v("Vous pouvez importer jusqu’à 50 sous-traitants par fichier.")])])],1),_c('div',{staticClass:"collaborators-col-4 tw-mt-4"},[_c('CollaborateurBlockTextComponent',{attrs:{"title":"2. Importez votre liste de sous-traitants","description":"Pour vous permettre de d’importer avec plus de faciliter la liste de vos sous-traitants, téléchargez ce modèle."}}),_c('vue-dropzone',{ref:"myVueDropzone",staticClass:"upload-file tw-mt-8",attrs:{"id":"dropzone","useCustomSlot":true,"options":_vm.dropzoneOptions,"includeStyling":false},on:{"vdropzone-removed-file":_vm.fileRemoved,"vdropzone-file-added":_vm.fileAdded}},[_c('div',{staticClass:"dropzone-custom-content tw-flex tw-items-center tw-flex-col"},[_c('img',{attrs:{"src":require("@/assets/images/icons/upload.svg")}}),_vm._v(" Glissez et déposez ici votre liste de sous-traitants "),_c('v-btn',{staticClass:"tw-text-white tw-cursor-pointer",attrs:{"elevation":"1","text":"","rounded":"","large":"","loading":_vm.isUploadModel}},[_vm._v(" Parcourir ")])],1)]),_c('div',{staticClass:"tw-w-full tw-flex"},[_c('v-btn',{staticClass:"tw-text-white tw-cursor-pointer tw-mx-auto",style:({
                backgroundColor: `${_vm.theme.ctaBgColor} !important`,
                color: `${_vm.theme.ctaTextColor} !important`,
              }),attrs:{"elevation":"1","text":"","rounded":"","large":"","loading":_vm.isImportFile},on:{"click":_vm.importFile}},[_vm._v(" Continuer ")])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }