<template>
  <div id="sous-traitants" class="overflow-auto">
    <v-row no-gutters class="mt-4 mb-12">
      <v-col cols="12">
        <h1
          class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl"
        >
          Vérifiez votre liste !
        </h1>
        <h5 class="tw-tracking-tight tw-text-base caption">
          Sélectionnez les sous-traitants auxquels vous voulez envoyer une
          invitation.
        </h5>
      </v-col>
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="table-sous-th">Raison sociale</th>
            <th class="table-sous-th">Contact</th>
            <th class="table-sous-th">Soumis par</th>
            <th class="table-sous-th">
              <div style="display: flex; align-items: center; justify-content: center;">
                Accepter
                <img
                  v-if="!accepter"
                  src="@/assets/images/icons/accepter-head.svg"
                  alt="Accepter"
                  class="tw-cursor-pointer"
                  @click="accepterAll"
                />
                <img
                class="tw-cursor-pointer"
                  v-else
                  src="@/assets/images/icons/refuser-head.svg"
                  alt="Accepter"
                  @click="accepterAll"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in table_data" :key="item.id">
            <td class="table-sous-td">{{ item.subcontractor.socialReason }}</td>
            <td class="table-sous-td">
              {{ item.subcontractor.user.firstName +" "+item.subcontractor.user.lastName }}
            </td>
            <td class="table-sous-td">{{ item.invitedBy.firstName +" "+item.invitedBy.lastName }}</td>
            <td class="table-sous-td">     
              <label :for="'Accepter[' + item.clientId + ']'" class="tw-flex tw-justify-center">
                <img
                  src="@/assets/images/icons/radio-input.svg"
                  v-if="!item.accepted"
                />
                <input
                  type="checkbox"
                  :id="'Accepter[' + item.clientId + ']'"
                  value="true"
                  class="tw-hidden"
                  v-model="item.accepted"
                />
                <img
                  src="@/assets/images/icons/radio-check.svg"
                  alt="check"
                  v-if="item.accepted"
                />
              </label></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-col cols="12" class="justify-end mt-4 mb-12 d-flex">
      <PrimaryButtonComponent class="confirme-btn tw-cursor-pointer" @click="confirme" v-if="confirmed.length>0">
      {{formSubmitButton }}
      </PrimaryButtonComponent>
    </v-col>
  </div>
</template>

<script>
import { getAttendedSubContractors } from "@/features/subcontractor/services/appApi";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "Verifier",
  computed: {
    formSubmitButton() {
      return "Confirmer";
    },
    confirmed() {
      return this.table_data.filter((item)=>{
        return item.accepted == true;
      });
    },
    accepterAllImg() {
      return this.accepter
        ? "@/assets/images/icons/accepter-head.svg"
        : "@/assets/images/icons/accepter-head.svg";
    },
  },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
      table_data: [],
      accepter: false,
    };
  },

  components: {
    PrimaryButtonComponent,
  },

  mounted() {
    this.get_pending_subcontractors(`page=${this.currentPage}`);
  },

  methods: {
    get_pending_subcontractors(page) {
      getAttendedSubContractors(page)
        .then((response) => {
          this.table_data = response.data.items.map((item)=> ({
            ...item,clientId : item.id, 
            accepted : false, 
          }));
          this.lastPage = response.data.meta.totalPages;
        })
        .catch((err) => {
          this.$snackbar.showAlertMessage({
            message: err.response.data.message,
            type: "error",
          });
        });
    },
    next() {
      this.$router.push({
        name: "SubContractor.PendingSubContractors.verifier.preview",
      });
    },
    confirme(){
        this.$store.dispatch('loadSubcontractorValid', this.confirmed).then(()=> this.next());
    },
    accepterAll() {
      if (this.accepter) {
        this.table_data.map(function (item) {
          item.accepted = 0;
        });
      } else {
        this.table_data.map(function (item) {
          item.accepted = 1;
        });
      }
      this.accepter = !this.accepter;
    },
  },
};
</script>

<style lang="scss">
.v-application ol,
.v-application ul {
  padding-left: 0 !important;
}

.send-btn {
  height: 45px !important;
  padding: 0 !important;
  font-size: 12px !important;
  min-width: 100px !important;
  width: 170px !important;
  margin: 0 12px;
  font-weight: normal !important;
  color: #fff !important;
}
th {
  div {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border: 0;
    img {
      margin-left: 10px;
    }
  }
}
</style>
