var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-col tw-h-full"},[_c('div',{staticClass:"tw-py-6 tw-px-24 tw-h-full tw-flex tw-flex-col tw-w-full tw-mx-auto",staticStyle:{"max-width":"1536px"}},[_c('img',{staticStyle:{"width":"170px"},attrs:{"src":_vm.logo,"alt":"LOGO"}}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-my-auto"},[_c('div',[_vm._m(0),_c('p',{staticClass:"tw-mt-6",staticStyle:{"line-height":"36px"}},[_c('span',{staticClass:"tw-font-medium tw-text-xl"},[_vm._v("À l’initiative du GPMSE, vous êtes entré dans un processus de labellisation.")]),_c('br'),_vm._m(1),_c('br'),_c('span',{staticClass:"tw-font-medium tw-text-xl",style:({ color: _vm.theme.ctaBgColor })},[_vm._v("Vous allez pouvoir évaluer la conformité de votre entreprise en accédant au questionnaire d'évaluation.")])]),_vm._m(2),_c('p',{staticClass:"tw-mt-8"},[_c('span',{staticClass:"tw-font-light tw-text-xl",style:({ color: _vm.theme.ctaBgColor })},[_vm._v("Nous vous souhaitons une bonne évaluation !")])]),_c('PrimaryButtonComponent',{staticClass:"tw-mt-8",style:({
            backgroundColor: `${_vm.theme.ctaBgColor} !important`,
            color: `${_vm.theme.ctaTextColor} !important`,
          }),attrs:{"elevation":"0"},on:{"click":function($event){return _vm.$router.push({ name: 'GdprQuizGpmse.register' })}}},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"500"}},[_vm._v("Poursuivre >")])])],1),_c('img',{staticStyle:{"width":"321px"},attrs:{"src":"/images/gpmse_landing.png","alt":"landing"}})])]),_c('Footer',{staticClass:"tw-w-full tw-mt-auto"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"40px"}},[_c('span',{staticClass:"tw-font-semibold tw-text-4xl"},[_vm._v("Bienvenue sur Viqtor®,")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"tw-font-semibold tw-text-2xl"},[_vm._v("la plateforme souveraine de la conformité RGPD.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"tw-font-medium tw-text-xl"},[_vm._v("Les dispositions que vous avez mises en place pour la protection des données personnelles "),_c('br'),_vm._v(" y jouent un rôle clé.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tw-mt-8",staticStyle:{"line-height":"36px"}},[_c('span',{staticClass:"tw-font-light tw-text-xl"},[_vm._v("Une fois que vous aurez valide votre questionnaire, votre score s'affichera automatiquement.")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"tw-font-light tw-text-xl"},[_vm._v("Les points de vigilance ou les \"trous dans la raquette\" vous seront signales.")])])
}]

export { render, staticRenderFns }