<template>
  <div class="rich-text-editor">
    <editor
      :api-key="apiKey"
      :init="editorConfig"
      v-model="internalValue"
      @input="updateValue"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'RichTextEditor',
  components: {
    editor: Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: [Number, String],
      default: 400
    },
    apiKey: {
      type: String,
      default: 'og07bd36aw60l2sr49omqxpwn7o70ufulbr5nnohgg47k6we'
    },
    autoResize: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  computed: {
    editorConfig() {
      const config = {
        menubar: false,
        statusbar: false, // Hide the status bar at the bottom
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen autoresize',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'bold italic underline removeformat numlist bullist link image undo redo',
        toolbar_align: 'center',
        content_style: `
          .mce-item-table,
          .mce-item-table[border="0"] *,
          .mce-item-table:not([border]) * {
            border: none !important;
          }
        `,
        visualblocks_default_state: false,
        browser_spellcheck: true,
        contextmenu: false,
        branding: false,
        resize: false,
        document_base_url: window.location.origin,
        
        // Keep style tags in content
        extended_valid_elements: 'style[type],link[rel|href|type]',
        valid_children: '+body[style],+body[link]',
        custom_elements: 'style,link',
        
        // Preserve styles
        preserve_styles: true,
        importcss_append: true,
        
        // Don't filter HTML too aggressively
        verify_html: false,
        
        // Allow style tags in head section
        allow_html_in_named_anchor: true,
        
        // Set content CSS mode to respect external styles
        content_css_cors: true,
        
        // Don't convert style attributes
        convert_styles_to_classes: false
      };
      
      if (this.autoResize) {
        // Configure auto-resize behavior
        config.autoresize_bottom_margin = 50;
        config.autoresize_min_height = 300;
        config.autoresize_overflow_padding = 20;
        config.autoresize_on_init = true;
      } else {
        // Use fixed height if autoResize is false
        config.height = this.height;
      }
      
      return config;
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== this.internalValue) {
        this.internalValue = newValue;
      }
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style scoped>
.rich-text-editor {
  width: 100%;
}

/* Remove dashed/dotted borders in editor */
:deep(.tox-tinymce) {
  border: 1px solid #ced4da !important;
  border-radius: 4px;
}

:deep(.mce-content-body [data-mce-selected="inline-boundary"]),
:deep(.mce-content-body [data-mce-selected]) {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Fix editor font appearance */
:deep(.tox .tox-edit-area__iframe) {
  background-color: #fff;
}

:deep(.tox-tinymce) {
  font-family: Roboto, 'Open Sans', sans-serif !important;
}

/* Center toolbar items */
:deep(.tox .tox-toolbar__primary) {
  justify-content: center !important;
}

:deep(.tox .tox-toolbar__group) {
  padding: 0 8px !important;
}
</style>
