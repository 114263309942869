<template>
  <div id="Questionnaire-rgbd" class="tw-flex tw-flex-col tw-h-full">
    <template v-if="loading_qst">
        <content-placeholders class="tw-mt-16 tw-px-6">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-py-6 tw-px-12">
        <img :src="logo" style="width: 170px;" alt="LOGO" />
        
        <EntrepriseHeaderBlockTextComponent
          class="tw-mt-10"
          :title="`Questionnaire RGPD de ${socialReason}`"
          description="Répondez à toutes les questions pour vous évaluer et établir votre score de conformité."
          titleFontSize="26px"
          descriptionFontSize="16px"
        />
  
        <div class="tw-w-full marginTop tw-max-w-[60rem] mt-12 tw-mx-auto" id="table">
          <ChapterComponent
            v-for="(chapter, key) in blueprint"
            :key="key"
            :chapter="chapter"
            :chapter-order-number="key+1"
            :saved-chapter="savedChapters[key]"
            :can-show-questions="selectedChapterId == chapter.id"
            @input-changed="checkIsCompleted"
            @selectChapter="selectChapterHandler"
          />
        </div>

        <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-12">
          <PrimaryButtonComponent
            :style="{
              backgroundColor: `${isCompleted && !isProcessing ? theme.ctaBgColor : '#F4F4F4'} !important`,
              color: `${isCompleted && !isProcessing ? theme.ctaTextColor : 'black'} !important`,
              borderColor: `${isCompleted && !isProcessing ? theme.ctaBgColor : '#F4F4F4'} !important`,
            }"
            :disabled="!isCompleted || isProcessing"
            :loading="isProcessing"
            @click="openPopup = true"
          >Voir mon évaluation</PrimaryButtonComponent>
        </div>
      </div>
    </template>

    <Footer class="tw-w-full tw-mt-auto" />

    <AccepterPopup :openPopup="openPopup" @fetchData="validate" @close="openPopup = false" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import Footer from "@/components/layout/Footer";
import ChapterComponent from "@/features/company/components/Questionnaire/RGPDChapterComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import AccepterPopup from "@/features/auth/components/gpmse/AccepterPopup";
import { getPublicQuiBlueprint } from "@/features/company/services/appApi";
import { quiz } from "@/features/auth/services/gpmse.api"
import { mapGetters } from "vuex";

export default {
  name: "GdprQuizGpmseQuiz",
  components: {
    EntrepriseHeaderBlockTextComponent,
    Footer,
    ChapterComponent,
    PrimaryButtonComponent,
    AccepterPopup,
  },
  data() {
    return {
      loading_qst: true,
      blueprint: [],
      savedChapters: [],
      chapters: [],
      selectedChapterId: 0,
      isCompleted: true,
      isProcessing: false,
      openPopup: false,
    }
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    socialReason() {
      return this.$store.state.gpmseCompanySocialReason;
    },
    ...mapGetters(["getGpmseQuizChapters"]),
  },
  mounted() {
    if (!this.$store.state.gpmseUserToken)
      this.$router.push({ name: "GdprQuizGpmse.register" });

    getPublicQuiBlueprint().then((response) => {
      this.blueprint = response.data.data;
      this.chapters = this.blueprint.map((item) => {
        return {
          chapterId: item.id,
          questions: item.questions.map((q) => {
            return {
              questionId: q.id,
              responses: q.responses.map((r) => {
                return {
                  id: r.id,
                  label: r.label,
                  responseId: null,
                };
              }),
            };
          }),
        };
      });
    })
    .catch((err) => {
      this.$snackbar.showAlertMessage({
        message: err.response.data.message,
        type: "error",
      });
    })
    .finally(() => {
      this.loading_qst = false;
    });

    // window.addEventListener('keydown', (e) => {
    //   if (e.key === 'f') this.fillAll();
    // });

    setTimeout(() => {
      this.savedChapters = this.getGpmseQuizChapters;
      if (this.savedChapters.length > 0) this.chapters = this.savedChapters;
    }, 2000);
  },
  methods: {
    fillAll() {
      this.chapters = this.blueprint.map((item) => {
        return {
          chapterId: item.id,
          questions: item.questions.map((q) => {
            return {
              questionId: q.id,
              responses: q.responses.map((r, index) => {
                return {
                  id: r.id,
                  label: r.label,
                  responseId: index === 0 ? r.id : null,
                };
              }),
            };
          }),
        };
      });
      this.savedChapters = this.chapters;
      this.isCompleted = true;
    },
    checkIsCompleted(chapterId, questions) {
      this.chapters = this.chapters.map((item) => {
        if (item.chapterId == chapterId) {
          return {
            chapterId,
            questions,
          };
        }
        return item;
      });

      const answeredChaptersCount = this.chapters.filter(
        (m) => m.questions.every(q => q.responses.some(r => !!r.responseId))
      ).length;

      this.isCompleted = answeredChaptersCount == this.blueprint.length;
    },
    selectChapterHandler(chapterId) {
      this.selectedChapterId = this.selectedChapterId == chapterId ? null : chapterId; 
    },
    validate() {
      this.isProcessing = true;

      quiz({ 
        token: this.$store.state.gpmseUserToken || "",
        quizForm: { chapters: this.chapters },
      })
      .then(({ data }) => {
        this.isProcessing = false;
        this.$store.commit("SET_GPMSE_QUIZ_CHAPTERS", this.chapters);
        this.$store.commit("SET_GPMSE_QUIZ_CHAPTERS_WITH_SCORE", data);
        this.$router.push({ name: "GdprQuizGpmse.score" });
      })
      .catch(err => {
        this.isProcessing = false;
        console.log(err);
        this.$snackbar.showAlertMessage({
          message: err.response.data.message,
          type: "error",
        });
      });
    },
  }
}
</script>