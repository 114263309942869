<template>
  <div id="Questionnaire-detail" class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <div class="tw-flex tw-items-center tw-justify-between">
        <img :src="logo" style="width: 170px;" alt="LOGO" />
        <span @click="logout" class="tw-cursor-pointer" :style="{ color: theme.ctaBgColor }">Se déconnecter</span>
      </div>

      <EntrepriseHeaderBlockTextComponent class="tw-mt-10" title="Back-office"
        :description="socialReason ? `Détail du questionnaire de ${socialReason}.` : ''" />

      <div class="tw-items-center tw-flex tw-flex-col">
        <template v-if="loading">
          <content-placeholders class="marginTop">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </template>
        <template v-else>
          <div class="!tw-mt-12" id="table" style="width: 60%;">
            <ChapterComponent
              v-for="(chapter, key) in blueprint"
              :key="key"
              :chapter="chapter"
              :chapter-order-number="key+1"
              :saved-chapter="quizDetail[key]"
              :can-show-questions="selectChapitre == chapter.id"
              readonly
              @selectChapter="handleChapitre"
            ></ChapterComponent>
          </div>
        </template>
  
        <v-btn v-if="!loading" class="tw-mt-4" elevation="1" text rounded large @click="$router.back()">Retour</v-btn>
      </div>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import ChapterComponent from "@/features/company/components/Questionnaire/RGPDChapterComponent";
import Footer from "@/components/layout/Footer";
import { getQuizDetail } from "@/features/gpmse/services/api";
import { getQuiBlueprint } from "@/features/company/services/appApi";

export default {
  name: "GdprQuizGpmseFollowupScoreDetail",
  components: {
    EntrepriseHeaderBlockTextComponent,
    ChapterComponent,
    Footer,
  },
  data() {
    return {
      loading: false,
      blueprint: [],
      selectChapitre: null,
      socialReason: null,
      quizDetail: [],
    }
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    parseIndex(index) {
      return typeof index === 'number' ? index : parseInt(index)
    },
    loadData() {
      this.loading = true;
      getQuizDetail(this.$route.params.userId).then((response) => {
        if(response.data.data){
          this.quizDetail = response.data.data.quiz.quizChapters.map((item) => {
            const questions = item.quizQuestions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses,
              };
            });
            return { chapterId: item.chapterId, questions: questions };
          });
        }
      }).then(()=>{
        this.fetchData();
        this.loading = false;
      });
    },
    async fetchData() {
      this.loading = true;
      await getQuiBlueprint().then((response) => {
        this.blueprint = response.data.data;
        this.chapters = this.blueprint.map((item) => {
          return {
            chapterId: item.id,
            questions: item.questions.map((q) => {
              return {
                questionId: q.id,
                responses: q.responses.map((r) => {
                  return {
                    id: r.id,
                    label: r.label,
                    responseId: null,
                  };
                }),
              };
            }),
          };
        });
        this.quizDetail = this.chapters.map(chapter => {
          return {
            chapterId: chapter.chapterId,
            questions: chapter.questions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses.map((response) => {
                  const isAnswerIncluded = !!this.quizDetail
                    .find((item) => item.chapterId == chapter.chapterId)
                    .questions.find((q) => q.questionId == question.questionId)
                    .responses.find((r) => r.id == response.id)

                  return {
                    id: response.id,
                    label: response.label,
                    responseId: isAnswerIncluded ? response.id : null,
                  };
                }),
              };
            }),
          }
        })
      })
      .catch((err) => {
        console.log(err);
        this.$snackbar.showAlertMessage({
          message: err?.response?.data?.message || 'Une erreur est survenue.',
          type: "error",
        });
      })
      .finally(() => {
        this.loading = false;
      });
    },
    handleChapitre(id) {
      if (this.selectChapitre === id) {
        this.selectChapitre = null;
      } else {
        this.selectChapitre = id;
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },
  },
}
</script>