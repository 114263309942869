var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-12 overflow-auto"},[_c('Breadcrumb',{attrs:{"divider":">"}}),_c('v-row',{staticClass:"mt-4 mb-12",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl"},[_vm._v(" Invitation à l’évaluation de vos sous-traitants ")]),_c('h5',{staticClass:"tw-tracking-tight tw-text-base caption"},[_vm._v(" Vous pouvez modifier l’invitation si besoin. ")])])],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4"},[_vm._v(" Aperçu de l’e-mail envoyé à vos sous-traitants ")])]),_c('div',{staticClass:"mail-preview",style:({ width: _vm.edit ? '100%' : 'auto' })},[(_vm.edit)?_c('rich-text-editor',{model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_c('div',{staticClass:"py-6 px-14",domProps:{"innerHTML":_vm._s(_vm.text)}})],1),_c('v-col',{staticClass:"my-4 d-flex justify-space-between",attrs:{"cols":"12"}},[(!_vm.edit)?_c('PrimaryButtonComponent',{staticClass:"edit-btn",style:({
        backgroundColor: `${_vm.referer.theme.ctaBgColor} !important`,
        color: `${_vm.referer.theme.ctaTextColor} !important`,
      }),on:{"click":function($event){_vm.edit = true}}},[_vm._v("Modifier")]):_vm._e(),(_vm.edit)?_c('PrimaryButtonComponent',{staticClass:"edit-btn",style:({
        backgroundColor: `${_vm.referer.theme.ctaBgColor} !important`,
        color: `${_vm.referer.theme.ctaTextColor} !important`,
      }),on:{"click":function($event){_vm.edit = false}}},[_vm._v("Annuler")]):_vm._e(),_c('PrimaryButtonComponent',{staticClass:"test-send-btn",style:({
        backgroundColor: `${_vm.referer.theme.ctaBgColor} !important`,
        color: `${_vm.referer.theme.ctaTextColor} !important`,
      }),attrs:{"loading":_vm.isSendingForm},on:{"click":_vm.test_invitation}},[_vm._v("Tester l’invitation ")]),_c('PrimaryButtonComponent',{staticClass:"next-btn",style:({
        backgroundColor: `${_vm.referer.theme.ctaBgColor} !important`,
        color: `${_vm.referer.theme.ctaTextColor} !important`,
      }),on:{"click":_vm.next_btn}},[_vm._v("Suivant")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }